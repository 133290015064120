<template>
  <b-card>
    <div class="card-header">
      <!-- Title & SubTitle -->
      <div>
        <b-card-title></b-card-title>
        <b-card-sub-title></b-card-sub-title>
      </div>
      <!-- Card Actions -->
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <feather-icon icon="HelpCircleIcon" v-b-toggle.sidebar-1 size="20" />
          </li>
        </ul>
      </div>
    </div>
    <div>
      <b-sidebar id="sidebar-1" title="Carga de Archivos" shadow bg-variant="dark" text-variant="light" width="450px"
        right backdrop>
        <div class="px-2 py-2">
          <p>
            En esta sección usted podr&aacute; realizar cargas masivas tanto de existencias como de las
            calidades
            (leyes) de sus analitos y podr&aacute; visualizar las cargas masivas a lo largo del tiempo.
          </p>
          <p>Aqu&iacute; encontrar&aacute; dos pestañas:
            La primera “Carga de Archivos” donde podr&aacute; subir datos de existencias y analitos.
            La segunda “Hist&oacute;rico de Carga” donde podr&aacute; ver los hist&oacute;ricos de archivos
            cargados en la
            plataforma.</p>
          <hr />
          <p>
            <strong>Stock existencias:</strong> En “Descargar ejemplo” usted puede bajar un archivo excel
            donde aparecer&aacute;n los campos necesarios a rellenar.
          </p>
          <p>Tambi&eacute;n podr&aacute; subir el archivo en la celda de abajo. Cabe mencionar que
            s&oacute;lo puede subir los
            archivos con este formato para una lectura &oacute;ptima.</p>
          <hr />
          <p>
            <strong>Calidades analitos:</strong> En “Descargar archivos” usted puede bajar un archivo excel
            donde aparecer&aacute;n los campos necesarios a rellenar para las calidades de analitos.
            En caso que no existan nuevos valores para la mineralog&iacute;a, por favor dejar en blanco o en valor
            0.
          </p>
          <p>Tambi&eacute;n podr&aacute; subir el archivo en la celda de abajo. Cabe mencionar que sólo puede subir los
            archivos con este formato para una lectura óptima.</p>
        </div>
      </b-sidebar>
    </div>
    <b-card-body>
      <b-tabs vertical content-class="col-12 col-md-9 mt-1 mt-md-0" pills nav-wrapper-class="col-md-3 col-12"
        nav-class="nav-left">
        <b-tab v-for="fileUploadTab in fileUploadTabs" :title="fileUploadTab.tabName" :key="fileUploadTab.tabName">
          <b-card-text>
            <b-card-title>
              Cargar Documento {{ fileUploadTab.tabName }}
            </b-card-title>
            <!-- info -->
            <b-alert variant="secondary" show>
              <div class="alert-body">
                <span></span>
                <b-link target="_blank" @click="downloadTemplateFile(fileUploadTab.templateFile)">
                  Descargar Ejemplo
                </b-link>
              </div>
            </b-alert>
            <b-row class="match-height">
              <b-col md="8">
                <b-form-file ref="form-file-inputs"
                  accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  :placeholder="'Selecciona ' + fileUploadTab.tabName"
                  drop-placeholder="Arrastra el archivo hasta acá ..." v-model="fileUploadTab.file">
                </b-form-file>
              </b-col>
              <b-col>
                <b-button variant="outline-primary" :disabled="!fileUploadTab.file"
                  @click="uploadDocument(fileUploadTab)">
                  <b-spinner v-if="fileUploadTab.isUploading" small class="mr-50" />
                  <feather-icon v-else icon="UploadIcon" class="mr-50" />
                  Cargar
                </b-button>
              </b-col>
            </b-row>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useApiServices from '@/services/useApiServices.js';
import axios from '@axios'

export default {
  name: "CargaData",
  data() {
    return {
      fileUploadTabs: [
        {
          tabName: "Stock Existencias",
          type: "EXISTENCIAS",
          file: null,
          isUploading: false,
          templateFile: useApiServices.documentsDownloadTemplate + "?attachment_type_name=EXISTENCIAS"
        },
        {
          tabName: "Calidades Analitos",
          type: "ANALITOS",
          file: null,
          isUploading: false,
          templateFile: useApiServices.documentsDownloadTemplate + "?attachment_type_name=ANALITOS"
        },
      ],
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted() {
   /*  axios.get("http://molymet.localhost:49939/api/test")
      .then((response) => {
        console.log({ tenant_response: response })
      })
      .catch((error) => {
        console.log(error);
      }); */
  },

  methods: {
    downloadTemplateFile(url) {
      return useApiServices.getDownloadFile(url);
    },
    uploadDocument(fileUploadTab) {
      var formData = new FormData();
      formData.append("documentFile", fileUploadTab.file);
      formData.append("documentType", fileUploadTab.type);

      if (fileUploadTab.file) {
        fileUploadTab.isUploading = true;

        useApiServices.postUploadDocument(formData, this)
          .then((response) => {
            console.log(response);
            fileUploadTab.isUploading = false;


            this.$toast(
              {
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Carga Exitosa`,
                  icon: "UploadIcon",
                  variant: "success",
                  html: `El documento <b>${fileUploadTab.file.name}</b> se ha cargado exitosamente.`,
                },
              },
              {
                onClose: () => {
                  fileUploadTab.file = null;
                },
              }
            );
            this.$emit("upload");
          })
          .catch((error) => {
            fileUploadTab.isUploading = false;

            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Ocurrio un Error`,
                icon: "UploadIcon",
                variant: "danger",
                html: `Ha ocurrido un error al subir el documento <b>${fileUploadTab.file.name}</b>.`,
              },
            });
          });
      } else {
      }
    },
  },
};
</script>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>
